import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { StoreService } from '../services/store.service';
import {DialogService} from 'primeng/dynamicdialog';
import { CoinDetailComponent } from "../components/coin-detail/coin-detail.component";

@Component({
  selector: 'app-search-list',
  templateUrl: './search-list.page.html',
  styleUrls: ['./search-list.page.scss'],
  providers: [DialogService]
})
export class SearchListPage implements OnInit {
  filteredList = [];

  constructor(
    public store: StoreService,
    public dialogService: DialogService,
    private modalController : ModalController
  ) { }

  ngOnInit() {
    this.filteredList = this.store.list;
  }

  onFilterKeyPressed(event: any) {
    this.filteredList = this.store.list.filter(coin => {
      return coin.name.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1;
    });
  }

  addCoin(coin) {
    const ref = this.dialogService.open(CoinDetailComponent, {
        data: {coin},
        header: 'Añadir '+ coin.name,
        width: '90%'
    });
  }

  closeModal() {
    this.modalController.dismiss();
  }

}
